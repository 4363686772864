<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action">
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-field :class="{'md-invalid': submitted && parentId == 0 }">
                                <label for="role">Danh mục cha</label>
                                <md-select v-model="parentId" name="parentId">
                                    <md-option v-for="item in parents" :key="item.id" :value="item.id">{{item.categoryName}}</md-option>
                                    <span class="md-error" v-if="submitted && parentId == 0">Vui lòng chọn danh mục cha</span>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field  :class="{'md-invalid': submitted && $v.entity.categoryId.$error }">
                                <label for="role">Danh mục</label>
                                <md-select v-model="entity.categoryId" name="categoryId">
                                    <md-option v-for="item in categories" :key="item.id" :value="item.id">{{item.categoryName}}</md-option>
                                    <span class="md-error" v-if="submitted && $v.entity.categoryId.$error">Vui lòng chọn danh mục</span>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="radio-group">
                                <label>Trạng thái</label>
                                <div class="form-control">
                                    <md-radio v-model="entity.isActive" :value="true">Publish</md-radio>
                                    <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.title.$error }">
                                <label for="title">Tiêu đề</label>
                                <md-input name="title" v-model="entity.title"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.title.required">Vui lòng nhập tiêu đề</span>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <span class="md-error" v-if="submitted && !$v.entity.content.required">Vui lòng nhập nội dung</span>
                            <vue-editor v-model="entity.content" :editor-toolbar="fullToolbar" style="height: 400px;"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>
<script>
    import helperService from '../../../api/helperService';
    import categoryHelperService from '../../../api/categoryHelperService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import { VueEditor } from "vue2-editor";

    export default ({
        components: {
            VueEditor
        },
        metaInfo: {
            title: 'Thêm/Cập nhật hướng dẫn sử dụng'
        },
        data() {
            return {
               title: '',
               id: 0,
               submitted: false,
               parentId: 0,
               entity: { id: 0, categoryId: 0, title: '', description: '', content: '', isActive: true },
               categories: [],
               parents: [],
               fullToolbar: [
                [{ font: [] }],
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ header: 1 }, { header: 2 }],
                ["blockquote", "code-block", "fullscreen"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],
                ["link", "image", "video", "formula"],
                [{ direction: "rtl" }],
                ["clean"]
                ]
            }
        },
        created(){
            this.id = this.$route.params.id;
            this.getParents();
            if(this.id > 0){
               this.title = 'Cập nhật hướng dẫn sử dụng';
               this.getById();
            }
            else{
               this.title = 'Thêm mới hướng dẫn sử dụng';
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                helperService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push("/helper/crud/" + response.data.id);
                        this.id = response.data.id;
                        this.getById();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                helperService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                helperService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.parentId = this.entity.category.parentId;
                        this.getChildren();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getChildren(){
                this.setLoading(true);
                categoryHelperService.gcetChildren(this.parentId).then((response) => {
                    if(response.statusCode == 200){
                        this.categories = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getParents(){
                this.setLoading(true);
                categoryHelperService.getAll(0).then((response) => {
                    if(response.statusCode == 200){
                        this.parents = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/helper');
            },
        },
        watch: { 
            parentId: function () { 
                this.getChildren(); 
            }, 
        },
        validations: {
            entity: {
                categoryId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                title: { required },
                content: { required }
            },
       }
    })

</script>
